html {
  background-color: #f6f2ef;
}

h1 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 10vw;

  color: black;
  text-transform: uppercase;
  margin: 0;
}

h2 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 4vw;
  
  color: black;
  margin: 0;
}

p {
  font-family: 'Rubik', sans-serif;
  font-size: 1.5em;

  color: black;
  margin: 1em 0 1em 0;
}

a {
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;

  color: black;
  text-decoration: underline;
  margin: 1em 0 1em 0;
}

a:hover {
  color: #cfc4ff;
}

span {
  display: flex;
}

.App {
  padding: 4vw;
}

.navigation {
  display: flex;
  justify-content: space-between;
}

.header {
  margin: 4vw 4vw 0 4vw;
}

.header__info {
  width: 17vw;
}

.header__title {
  text-align: center;
}

.header__title_2 {
  margin-left: 8vw;
}

.header__gradient_1 {
  height: 12vw;
  width: 12vw;
  border-radius: 30px;
  margin: 0 10px 0 10px;

  background-image: url("./gradient1.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.header__gradient_2 {
  height: 5vw;
  width: 5vw;
  border-radius: 30px;
  margin: 0 10px 0 10px;

  background-image: url("./gradient2.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.header__title__img {
  border-radius: 30px;
  margin: 0 0 0 4vw;
  width: 19vw;
}

.honour {
  margin: 0 4vw 0 4vw;
}

.honour__posts {
  width: 100%;
  margin: -4vw 0 0 0;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 4vw;
  row-gap: 4vw;
  grid-template-areas: 
    "post1 ."
    "post1 post2"
    ". post2"
}

.linkedin-post p, li {
  font-family: 'Rubik', sans-serif;
  font-size: 1.25rem;;
}

.linkedin-post-author {
  display: flex;
  position: relative;
  align-items: center;

  width: 40%;
  border-radius: 30px;
  padding: 1em 2em;

  z-index: 1;
}

.linkedin-post-author p {
  padding-left: 1em;
  margin: 0;
}

.linkedin-post-author-img {
  height: 5vw;
  border-radius: 50%;
}

.linkedin-post-info {
  width: auto;

  border-radius: 30px;
  background-color: white;

  padding: 1em 2em;
  margin-left: 5vw;
  margin-top: -20px;

  z-index: -1;
}

.linkedin-post-info p, li {
  font-size: 1rem;
}

.linkedin-post-img {
  border-radius: 30px;
  width: 100%;
}

.post-1 {
  grid-area: post1;
}
.post-1 .linkedin-post-author {
  background-color: #cfc4ff;
}

.post-2 {
  grid-area: post2;
}
.post-2 .linkedin-post-author {
  background-color: #a3ecff;
}

.show-small-screen {
  display: none;
}

.show-medium-screen {
  display: none;
}

@media only screen and (max-width: 1500px) {
  .header__info {
    width: 30vw;
  }
}

@media only screen and (max-width: 1200px) {
  .header__info {
    width: 50vw;
  }
}

@media only screen and (max-width: 930px) {
  .hide-medium-screen {
    display: none;
  }

  .show-medium-screen {
    display: block;
  }

  .navigation {
    margin: 4vw 4vw 0 4vw;
  }

  .header__title__img {
    width: 75%;
    margin: 0;
  }

  span {
    align-items: center;
  }

  .header__title_2 {
    margin: 0;
    justify-content: center;
  }

  h2 {
    font-size: 10vw;
  }

  .header__gradient_2 {
    height: 10vw;
    width: 10vw;
    border-radius: 12px;
    margin: 0 10px 0 10px;
  }
}

@media only screen and (max-width: 620px) {
  .hide-small-screen {
    display: none;
  }

  .show-small-screen {
    display: block;
  }

  .header__title_2 {
    display: none;
  }

  .header__info {
    width: 100%;
  }

  .header__title__img {
    width: 100%;
  }
}